html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

b,
strong {
  font-weight: 700;
}
html {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}
body {
  background-color: #fdfdfd;
  color: $black-color;
  margin: 0;
  font-size: 1rem;
  text-align: left;
}

// Prevent highlight on mobile
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
}

a {
  color: $link-color;
  text-decoration: none;
  background-color: transparent;
  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: none;
  }
}

#root {
  overflow: hidden;
}

.page-loader {
  height: 100vh;
  width: 100vw;
  display: flex;
}

.loading-img {
  margin: auto;
}
